import React, { useEffect, useLayoutEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSite } from "../../_foundation/hooks/useSite";
import { useSelector } from "react-redux";
import { loginStatusSelector } from "../../redux/selectors/user";
import AdobeService from "../../_foundation/adobeService/adobeService";
interface childLinksProps {
  value?: any;
  open?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      background: "#0c1218",
      color: "#a8a8a8",
      fontSize: "20px",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

const FooterSm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openAcc, setOpenAcc] = React.useState(false);
  const [openPerform, setOpenPerform] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [openResource, setOpenResource] = React.useState(false);
  const [openRelated, setOpenRelated] = React.useState(false);
  const { mySite } = useSite();
  const loginStat = useSelector(loginStatusSelector);

  const Accessories = [
    {
      linkText: t("Footer.Accessories.Interior"),
      linkURL: "/lincoln-interior",
    },
    {
      linkDescription: t("Footer.Accessories.Exterior"),
      linkText: t("Footer.Accessories.Exterior"),
      linkURL: "/lincoln-exterior",
    },
    {
      linkText: t("Footer.Accessories.Electronics"),
      linkURL: "/lincoln-electronics",
    },
    {
      linkText: t("Footer.Accessories.Wheels"),
      linkURL: "/lincoln-wheels",
    },
    {
      linkText: t("Footer.Accessories.FordAccessories"),
      linkURL: "https://www.ford.com/accessories",
    }
  ];

  const isLoggedIn = loginStat;
  var protocol = window.location.protocol;
  var port = !window.location.port.trim() ? "" : ":" + window.location.port;
  var url = window.location.href;
  var currentURL =
    url.indexOf("&krypto") > 0 ? url.substring(0, url.indexOf("&krypto")) : url;
  const FMA_LOGIN_LINK = `${
    mySite.storeCfg.userData["FMA_SIGN_IN"]
  }?redirectURL=${protocol}//${
    window.location.hostname
  }${port}/LoginRedirect?storeId=${
    mySite.storeID
  }&catalogId=${mySite.catalogID}&langId=-1&currentURL=${encodeURIComponent(
    currentURL
  )}`;
  const FMA_LOGOUT_LINK = `${
    mySite.storeCfg.userData["FMA_SIGN_OUT"]
  }?redirectURL=${encodeURIComponent(
    `${protocol}//${window.location.hostname}${port}/LogoutRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&lang=en_us`
  )}&currentURL=${encodeURIComponent(
    window.location.origin
  )}&pid=${localStorage.getItem(
    "pid"
  )}&sessionId=${localStorage.getItem("sessionId")}`;
  const MyProfile = [
    loginStat
      ? {
          linkText: t("LOGOUT"),
          linkURL: FMA_LOGOUT_LINK,
        }
      : {
          linkText: t("LOGIN"),
          linkURL: FMA_LOGIN_LINK,
        },
    !loginStat
      ? {
          linkText: t("Create An Account"),
          linkURL:FMA_LOGIN_LINK,
        }
      : {},
    {
      linkText: t("Footer.MyProfile.AccountDetails"),
      linkURL: isLoggedIn ? "/account/addresses" : FMA_LOGIN_LINK,
    },
    {
      linkText: t("Footer.MyProfile.OrdersAndReturns"),
      linkURL: isLoggedIn ? "/account/order-history" : FMA_LOGIN_LINK,
    },
    {
      linkText: t("Footer.MyProfile.MyGarage"),
      linkURL: isLoggedIn ? "/account/my-garage" : FMA_LOGIN_LINK,
    },
  ];

  const Resources = [
    {
      linkText: t("Footer.Resources.FAQ"),
      linkURL: "/faq",
    },
    {
      linkText: t("Footer.Resources.ContactUs"),
      linkURL: "/contact-us",
    },
    {
      linkText: t("Footer.Resources.ShippingAndDelivery"),
      linkURL: "/shipping-delivery",
    },
    {
      linkText: t("Footer.Resources.Returns"),
      linkURL: "/return",
    },
    {
      linkText: t("Footer.Resources.TermsAndConditions"),
      linkURL: "/terms-conditions",
    },
    {
      linkText: t("Footer.Resources.WarrantyInfo"),
      linkURL: "/warranty-information",
    },
  ];

  const RelatedLinks = [
    {
      linkText: t("Footer.RelatedLinks.Ford"),
      linkURL: "https://www.ford.com/",
    },
    {
      linkText: t("Footer.RelatedLinks.Lincoln"),
      linkURL: "https://www.lincoln.com/",
    },
    {
      linkText: t("Footer.RelatedLinks.ContactFord"),
      linkURL: "https://corporate.ford.com/contact.html",
    },
    {
      linkText: t("Footer.RelatedLinks.Racing"),
      linkURL: "https://performance.ford.com/home.html",
    },
    {
      linkText: t("Footer.RelatedLinks.FordParts"),
      linkURL: "https://parts.ford.com/en.html",
    },
    {
      linkText: t("Footer.RelatedLinks.FordCollision"),
      linkURL: "https://collision.ford.com/",
    },
    {
      linkText: t("Footer.RelatedLinks.PowerStrokeDiesel"),
      linkURL: "https://powerstrokediesel.com/",
    },
    {
      linkText: t("Footer.RelatedLinks.TireFinder"),
      linkURL: "https://www.ford.com/support/service-maintenance/tire-finder/",
    },
    {
      linkText: t("Footer.RelatedLinks.VintageParts"),
      linkURL: "https://www.vpartsinc.com/",
    },
  ];
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={`${classes.root} footer-nav__list`}>
      <ListItem
        onClick={() => setOpenAcc(!openAcc)}
        className="footer-nav__column"
        style={{ padding: "0px" }}>
        <ListItemText
          style={{ marginTop: "0px" }}
          className="footer-nav__column-title">
          {"ACCESSORIES"}
        </ListItemText>
      </ListItem>
      <List component="div" disablePadding>
        {openAcc &&
          Accessories.map((v: any) => (
            <ListItem className={`${classes.nested} footer-nav__list-item`}>
              <ListItemText className="item-txt">
                {v.linkText == "Ford Accessories" ? (
                  <a
                    href={v.linkURL}
                    className="footer-nav__link  uc title--xxs"
                    target="_blank">
                    {v.linkText}
                  </a>
                ) : (
                  <Link
                    className="footer-nav__link  uc title--xxs"
                    key={v.linkURL + "/"}
                    to={v.linkURL}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}>
                    {v.linkText}
                  </Link>
                )}
              </ListItemText>
            </ListItem>
          ))}
      </List>

      <ListItem
        onClick={() => setOpenProfile(!openProfile)}
        className="footer-nav__column"
        style={{ padding: "0px" }}>
        <ListItemText
          style={{ marginTop: "0px" }}
          className="footer-nav__column-title">
          {"MY PROFILE"}
        </ListItemText>
      </ListItem>

      <List component="div" disablePadding>
        {openProfile &&
          MyProfile.map(
            (v: any) =>
              v.linkURL && (
                <ListItem className={`${classes.nested} footer-nav__list-item`}>
                  <ListItemText className="item-txt">
                    {
                      <a
                        className="footer-nav__link  uc title--xxs"
                        href={v.linkURL}>
                        {v.linkText}
                      </a>
                    }
                  </ListItemText>
                </ListItem>
              )
          )}
      </List>

      <ListItem
        onClick={() => setOpenResource(!openResource)}
        className="footer-nav__column"
        style={{ padding: "0px" }}>
        <ListItemText
          style={{ marginTop: "0px" }}
          className="footer-nav__column-title">
          {"RESOURCES"}
        </ListItemText>
      </ListItem>

      <List component="div" disablePadding>
        {openResource &&
          Resources.map((v: any) => (
            <ListItem className={`${classes.nested} footer-nav__list-item`}>
              <ListItemText className="item-txt">
                <Link
                  className="footer-nav__link  uc title--xxs"
                  key={v.linkURL + "/"}
                  to={v.linkURL}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}>
                  {v.linkText}
                </Link>{" "}
              </ListItemText>
            </ListItem>
          ))}
      </List>

      <ListItem
        onClick={() => setOpenRelated(!openRelated)}
        className="footer-nav__column"
        style={{ padding: "0px" }}>
        <ListItemText
          style={{ marginTop: "0px" }}
          className="footer-nav__column-title">
          {"RELATED LINKS"}
        </ListItemText>
      </ListItem>

      <List component="div" disablePadding>
        {openRelated &&
          RelatedLinks.map((v: any) => (
            <ListItem className={`${classes.nested} footer-nav__list-item`}>
              <ListItemText className="item-txt">
                {
                  <a
                    className="footer-nav__link  uc title--xxs"
                    href={v.linkURL}>
                    {v.linkText}
                  </a>
                }
              </ListItemText>
            </ListItem>
          ))}
      </List>
    </List>
  );
};
export default FooterSm;
